import * as React from "react"
import {StaticImage} from "gatsby-plugin-image"

import {Layout} from "../components/Layout";

export default function MtbKettingOnderhoud() {
    return (
        <Layout restrictedWidth={false}
                pageTitle={"Jouw MTB toe aan ketting onderhoud?✓ | Steven's Bike Service"}
                metaDescription={"Is jouw mountainbike toe aan ketting onderhoud? Wij voeren dit graag voor je uit! Steven's Bike Service - dé MTB specialist van regio Deventer!✓"}>

            <div className={"fixed top-20 z-0 p-0"}>
                <StaticImage className={"landscape:w-screen portrait:h-screen"}
                             src={"../images/sbs-mtb-werkplaats.jpg"} alt={"Lekker ragguh"}/>
            </div>
            <div className={"h-[65vh]"}></div>

            <div className={"bg-white z-20 w-full"}>
                <div className=" max-w-6xl mx-auto lg:content-between gap-y-6">
                    <h2 className="text-4xl font-extrabold mt-16">Mountainbike ketting onderhoud</h2>
                    <p className={"leading-relaxed md:leading-relaxed lg:leading-relaxed mt-5 px-2 col-span-2 text:l md:text-xl lg:text-xl py-3"}>
                        Door zelf regelmatig wat liefde aan de ketting te geven, zorg je dat je ketting, tandwielen en
                        bladen langer meegaan. Bovendien presteert je aandrijving beter en vereist minder werk van jouw
                        kant.
                    </p>
                    <h3 className="text-2xl font-extrabold mt-3">MTB ketting onderhoud: dry-lube vs wet-lube</h3>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Het schoonmaken van je mountainbike ketting is een relatief eenvoudig proces dat in een paar
                        minuten gedaan kan worden. Doordat ik zelf al meer dan 20 jaar mountainbike heb ik zelf al de
                        nodige smeermiddelen voorbij zien komen.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Je kunt deze onderverdelen in twee groepen; dry-lube en wet-lube. Beide zijn, anders dan de naam
                        van dry-lube doet vermoeden, vloeibaar. Het grote verschil van de twee is dat de dry-lube voor
                        droge omstandigheden is en de wet-lube voor natte omstandigheden. Voordeel van de dry-lube is
                        dat die minder of geen vuil aanpakt en alles dus veel schoner blijft.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Voordeel van de wet-lube is dat die beter blijft zitten en beter beschermt in extreme en natte
                        omstandigheden. Het nadeel van een wet-lube is dat je ketting, tandwielen en voorblad(en) erg
                        vies worden en het goed schoonmaken een behoorlijke klus wordt.
                    </p>
                    <h3 className="text-2xl font-extrabold mt-3">MTB ketting onderhoud: Squirt</h3>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Zelf rijd ik nu al meer dan 10 jaar met Squirt (niet googlen ;) en ik zweer hierbij. Ook in de
                        meest extreme omstandigheden blijft deze dry-lube de ketting beschermen en smeren. Met Squirt
                        hoef je de ketting nooit meer te ontvetten, dus geen geknooi met vette zwarte kettingen in een
                        kettingborstelbakje of met ontvetter.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Squirt Lube is een biologisch afbreekbare lube die de
                        levensduur van de volledige aandrijftrein verlengt. De emulsie bevat wel 40 soorten wax die elk
                        een specifieke functie hebben. Na het aanbrengen dringt de vloeistof door tot in het binnenste
                        van de ketting. Het water verdampt langzaam en de wax laat een laagje achter dat water,- zand-
                        en vuilafstotend is.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Squirt Lube bevat geen alcohol of ander schadelijk oplosmiddel en is op die
                        manier ook duurzaam. Omdat water langzaam verdampt, en de wax alleen in vloeibare vorm tot het
                        binnenste van de ketting kan doordringen, is er meer tijd voor de wax om zich in de openingen
                        tussen de binnenste pinnetjes, rolletjes en plaatjes van de ketting te nestelen.
                    </p>
                    <h3 className="text-2xl font-extrabold mt-3">Hoe Squirt te gebruiken tijdens het mountainbike
                        ketting onderhoud?</h3>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Stap 1: <em>Alleen bij eerste gebruik: Ontvet de ketting, cassette, voorblad(en) en
                        derailleurwieltjes zo grondig mogelijk, zodat alle fabrieksolie verwijderd wordt. Nu mag je dus
                        wel ontvetter gebruiken zoals bijvoorbeeld <a
                            href={"https://eu.muc-off.com/collections/bicycle-cleaning/products/bio-drivetrain-cleaner"}
                            className={"text-blue-600 hover:underline"}
                            target={"_blank"}>Muc off bio drivetrain cleaner</a>.</em>
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Bij elk volgende gebruik neem je de ketting, cassette, voorblad(en) en derailleurwieltjes af met
                        een droge doek.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Stap 2: Ketting moet droog zijn; fles goed schudden. Smeer twee laagjes op het gedeelte van de
                        ketting wat over de tandwielen loopt, door aan de cranks te draaien. Laat de eerste laag drogen
                        voordat je de volgende aanbrengt. Je hoeft overtollige wax niet te verwijderen en de
                        aandrijftrein nooit meer te ontvetten!
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Dat is het! Je ketting is nu schoon en klaar voor een nieuwe rit.
                    </p>

                    <h3 className="text-2xl font-extrabold mt-3">Hoe vaak moet je de MTB ketting smeren?</h3>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Hoe vaak je dit moet doen hangt er natuurlijk vanaf waar je rijdt en in welke omstandigheden.
                        Maar als je de mountainbikeketting om de twee a drie ritten met squirt smeert, of na iedere
                        natte rit, dan zit je zeker goed.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Door deze eenvoudige stappen te volgen, kun je de levensduur van je mountainbikeketting helpen
                        verlengen en vele kilometers genieten van soepele ritten!
                    </p>
                    <h3 className="text-2xl font-extrabold mt-3">Mountainbike ketting rek</h3>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Na het smeren van de ketting, is het goed om de slijtage van de ketting in de gaten te houden.
                        Een versleten ketting valt minder mooi in de tanden van de cassette en voorblad(en).
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Hij gaat daardoor op de toppen van de tanden lopen en uiteindelijk springt de ketting over de
                        vertanding heen. Hierdoor zal de aandrijving veel sneller slijten en de levensduur aanzienlijk
                        verlagen.
                    </p>

                    <div className={"max-w-xs mx-auto"}>
                        <figure className="mx-auto">
                            <StaticImage src={"../images/sbs-fietsketting.gif"} alt={"fietsketting"}
                                         className={"shadow-sm bg-white shadow-gray-400 mx-auto mb-2"}/>

                            <figcaption className="text-center">
                                Een ketting bestaat uit vijf onderdelen.
                            </figcaption>
                        </figure>
                    </div>

                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Doordat de pen, bus en rol op elkaar inslijten “rekt’ de ketting. Het is dus niet zo dat het
                        metaal van de ketting echt rekt. Doordat de ketting uit wel meer dan honderd schakels bestaat,
                        en je dus wel meer dan honderd keer het inslijten van de pen, bus en rol hebt, wordt de ketting
                        langer.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Met een kettingslijtagemeter meet je deze slijtage. Als je, met een twaalf speed, onder
                        de 0,5% rek blijft en onder de 0,75% rek bij alle andere aandrijvingen kun je vaak twee tot drie
                        kettingen per cassette gebruiken. Vooral bij de duurdere cassettes loont dat al snel omdat deze
                        al gauw meer dan €150,- kosten.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Zit je boven deze rek dan zal een nieuwe ketting, zeker onder
                        zware belasting over de ingesleten tanden heen schieten, ook wel bekend als doortrappen. In dat
                        geval kun je beter de aandrijving helemaal op fietsen en alles in één keer vervangen.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Hoe snel het slijt hangt ook af van hoe je fietst. Ben je iemand die altijd op de pedalen staat
                        te stampen omdat een van je maten weer je KOM heeft afgepakt? Dan gaat het vroegtijdig wisselen
                        van de ketting niet altijd goed omdat dan tandwielen op de cassette al te ver zijn versleten.
                        Vaak zijn met name de kleinste tandwielen op de cassette als eerste op. Bij sommige cassettes
                        kun je deze ook los vervangen.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Twijfel je nog over de staat van je aandrijving? Neem dan nu contact op met Steven's Bike
                        Service! Ik voer dit onderhoud van de ketting van jouw mountainbike graag uit! Neem nu contact
                        met mij op via whatsapp of bel me en ik zorg dat jouw MTB weer als de brandweer gaat!
                    </p>
                </div>
            </div>
        </Layout>
    )
}
